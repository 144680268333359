<template>
  <TTView>
    <VRow>
      <VCol>
        <TestCard :test="test" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TestCard from '../../../components/multi-level-test/tests/TestCard.vue';

export default {
  name: 'MultiLevelTestsView',

  components: {
    TestCard,
  },

  data() {
    return {
      loading: false,
      test: {
        name: null,
        description: null,
        skillId: null,
        config: {
          questionThreshold: 0,
          timeout: 0,
          cooldown: 0,
        },
      },
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { testId } = this;
        const data = { id: testId };
        const { test } = await this.$di.api.MultiLevelTest.TestsGet({ data });

        this.test = test;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
